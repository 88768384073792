import React, { useRef } from "react";
import mapboxgl from "mapbox-gl";
import Popup from "../popup/popup";
import { ValidateCoordinates } from "../../services/coordinatesValidator";
import { flyToLocation } from "../../services/flyTo";
import { config } from "../../services/config";
const Landmarks = ({ map, mapIcons, landmarks, theme }) => {
  const markersRef = useRef([]);

  const parseCoordinates = (coordinatesString) => {
    const [lat, lng] = coordinatesString.split(",").map(Number); // Split by comma and convert to numbers
    return [lng, lat]; // Reverse to [lng, lat] format
  };

  React.useEffect(() => {
    if (map && landmarks && landmarks.length > 0) {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      const sortedLandmarks = landmarks.sort((a, b) => b.order - a.order);
      sortedLandmarks.forEach((landmark) => {
        if (
          landmark.showOnMap &&
          landmark.coordinates &&
          landmark.mapIcon &&
          ValidateCoordinates(landmark.coordinates)
        ) {
          // Create a DOM element for the marker
          const el = document.createElement("div");
          el.className = "marker";
          const img = new Image();
          img.src = `${config.baseUrl + landmark?.mapIcon?.url}`;
          img.onload = function () {
            el.style.backgroundImage = `url(${img.src})`;
            el.style.width = `${img.naturalWidth}px`;
            el.style.height = `${img.naturalHeight}px`;
            el.style.backgroundSize = "100%";
          };
          el.style.backgroundSize = "100%";
          el.style.backgroundRepeat = "no-repeat";
          el.style.cursor = "pointer";

          // Create the popup
          const popup = new mapboxgl.Popup({ offset: 50, closeButton: true })
            .setHTML(Popup({ theme, item: landmark }))
            .on("open", () => {
              // Custom styling for the close button
              const closeButton = document.querySelector(
                ".mapboxgl-popup-close-button"
              );
              if (closeButton) {
                closeButton.textContent = ""; // Remove the "×"
              }
            });

          // Create and add the marker
          const landmarkCoordinates = parseCoordinates(landmark.coordinates);
          const landmarkMarker = new mapboxgl.Marker(el)
            .setLngLat(landmarkCoordinates)
            .setPopup(popup)
            .addTo(map);

          markersRef.current.push(landmarkMarker);

          el.addEventListener("click", () => {
            // Add popup to the map
            popup.addTo(map);
            flyToLocation(map, landmarkCoordinates);
          });
        }
      });
    }
    return () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
    };
  }, [map, landmarks, theme]);

  return null;
};

export default Landmarks;
