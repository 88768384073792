// DubaiRuns.js
import React, { useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import Popup from "../popup/popup";
import { GeolocateControl } from "mapbox-gl";
import { config } from "../../services/config";
import { flyToLocation } from "../../services/flyTo";
import { addRoute } from "../../services/getRoutes";
import {
  ValidateCoordinates,
  compareCoordinates,
} from "../../services/coordinatesValidator";

const DubaiRuns = ({ map, dubaiRuns, mapIcons, theme }) => {
  const markersRef = useRef([]);
  const [userLocation, setUserLocation] = useState(null);
  const showDirection = false;
  let layerRef = useRef([]);
  const geolocate = new GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: true,
    showUserHeading: true,
  });

  geolocate.on("geolocate", (e) => {
    const lon = e.coords.longitude;
    const lat = e.coords.latitude;
    setUserLocation([lon, lat]);
  });

  const parseCoordinates = (coordinatesString) => {
    return coordinatesString
      .trim() // Remove extra spaces and newlines
      .split("\n") // Split by newline
      .map((coord) => {
        const [lat, lng] = coord.split(",").map(Number); // Split by comma and convert to numbers
        return [lng, lat]; // Reverse to [lng, lat] format
      });
  };

  React.useEffect(() => {
    if (map && dubaiRuns && dubaiRuns.length > 0) {
      // Clear previous markers
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current = [];

      dubaiRuns.forEach((track, index) => {
        if (
          track.showOnMap &&
          track.routeCoordinates &&
          ValidateCoordinates(track.routeCoordinates)
        ) {
          const trackCoordinates = parseCoordinates(track.routeCoordinates);
          const sourceId = `run-${index}`;
          const layerId = `run-${index}`;

          // Add the track line
          if (!map.getSource(sourceId)) {
            map.addSource(sourceId, {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {
                  name: track.name,
                  description: track.description,
                },
                geometry: {
                  type: "LineString",
                  coordinates: trackCoordinates,
                },
              },
            });
          }

          if (!map.getLayer(layerId)) {
            map.addLayer({
              id: layerId,
              type: "line",
              source: sourceId,
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": track?.theme ? track?.theme : theme.theme,
                "line-width": theme.useThickLine ? 6 : 3,
              },
            });
            layerRef.current.push(layerId);
          } else {
            map.setLayoutProperty(layerId, "visibility", "visible");
            layerRef.current.push(layerId);
          }

          // Add start pointer
          let startIcon = track?.startIcon?.url
            ? track?.startIcon?.url
            : mapIcons?.start?.icon?.url;
          const startEl = document.createElement("div");
          startEl.className = "jogging-marker";
          startEl.id = "jogging-marker";
          startEl.style.backgroundImage = `url(${config.baseUrl + startIcon})`;
          startEl.style.width = "52px";
          startEl.style.height = "52px";
          startEl.style.backgroundSize = "100%";
          startEl.style.cursor = "pointer";
          startEl.style.zIndex = "3";

          const PopupId = `runBtn-${index}`;
          const googleDirectionId = `runGoogleBtn-${index}`;

          // Create the popup
          const popup = new mapboxgl.Popup({ offset: 35, closeButton: true })
            .setHTML(Popup({ theme, item: track, PopupId, googleDirectionId }))
            .on("open", () => {
              // Custom styling for the close button
              const closeButton = document.querySelector(
                ".mapboxgl-popup-close-button"
              );
              if (closeButton) {
                closeButton.textContent = ""; // Remove the "×"
              }
            });

          // Create and add the marker
          const startMarker = new mapboxgl.Marker(startEl)
            .setLngLat(trackCoordinates[0])
            .setPopup(popup) // sets a popup on this marker
            .addTo(map);
          markersRef.current.push(startMarker);

          map.on("click", () => {
            setTimeout(() => {
              const directionBtn = document.getElementById(PopupId);
              if (directionBtn) {
                directionBtn.addEventListener("click", () => {
                  popup.remove();
                  if (userLocation) {
                    addRoute(
                      userLocation,
                      trackCoordinates[0],
                      map,
                      `addRoute-11`
                    );
                  } else {
                    if ("geolocation" in navigator) {
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          const newUserLocation = [
                            position.coords.longitude,
                            position.coords.latitude,
                          ];
                          setUserLocation(newUserLocation);
                          addRoute(
                            newUserLocation,
                            trackCoordinates[0],
                            map,
                            `addRoute-11`
                          );
                        },
                        () => {
                          alert(
                            "Please enable location services to get directions."
                          );
                          geolocate.trigger();
                        }
                      );
                    } else {
                      alert(
                        "Geolocation is not supported by your browser. Please use a modern browser to get directions."
                      );
                    }
                  }
                });
              }
              const googleMapBtn = document.getElementById(googleDirectionId);
              if (googleMapBtn) {
                googleMapBtn.addEventListener("click", () => {
                  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&map_action=map&center&destination=${trackCoordinates[0][1]},${trackCoordinates[0][0]}&zoom=17`;
                  window.open(googleMapsUrl, "_blank");
                });
              }
            }, 0);
          });

          startEl.addEventListener("click", () => {
            // Center the map on the marker's location using map.flyTo
            if (trackCoordinates[0]) {
              flyToLocation(map, trackCoordinates[0]);
            }
          });
          // Add end pointer
          if (
            !compareCoordinates(
              trackCoordinates[0],
              trackCoordinates[trackCoordinates.length - 1]
            )
          ) {
            let endIcon = track?.endIcon?.url
              ? track?.endIcon?.url
              : mapIcons?.end?.icon?.url;
            const endEl = document.createElement("div");
            endEl.className = "jogging-marker";
            endEl.style.backgroundImage = `url(${config.baseUrl + endIcon})`;
            endEl.style.width = "28px";
            endEl.style.height = "28px";
            endEl.style.backgroundSize = "100%";
            endEl.style.cursor = "pointer";

            const endMarker = new mapboxgl.Marker(endEl)
              .setLngLat(trackCoordinates[trackCoordinates.length - 1])
              .setPopup(
                new mapboxgl.Popup({ offset: 35, closeButton: true }).setHTML(
                  Popup({
                    theme,
                    item: track,
                    showDirection,
                    PopupId,
                    googleDirectionId,
                  })
                )
              )
              .addTo(map);
            markersRef.current.push(endMarker);

            endEl.addEventListener("click", () => {
              const endCoordinates =
                trackCoordinates[trackCoordinates.length - 1];
              if (endCoordinates) {
                flyToLocation(map, endCoordinates);
              }
            });
          }
        }
      });
    }
    return () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current.forEach((layer) =>
        map.setLayoutProperty(layer, "visibility", "none")
      );
    };
  }, [
    map,
    dubaiRuns,
    mapIcons?.start?.icon?.url,
    mapIcons?.end?.icon?.url,
    theme,
    userLocation,
    geolocate,
    showDirection,
  ]);

  return null;
};

export default DubaiRuns;
