import directionImage from "./../../assets/direction-icon.svg";
import { config } from "../../services/config";
const Popup = ({ theme, item, PopupId, googleDirectionId }) => {
  return `
    <div class="popup-content" style="background: ${theme.theme};">
      <div class="popup-content__wrapper">
        ${
          item?.thumbnail?.url
            ? `<div class="popup-image">
              <img src="${
                config.baseUrl + item?.thumbnail?.url
              }" alt="image" style="" /> 
            </div>`
            : ""
        }
        <div class="popup-text">
         <h3 class="popup-text__title" style="
            color: ${theme.isLightTheme ? "white" : "black"};
            width: ${item?.thumbnail?.url ? "" : "95%"}">
            ${item?.title}
        </h3>
          <div class="popup-text-mid" style="display: ${
            item?.shortDescription ? "block" : "none"
          };">
            ${
              item?.shortDescription
                ? `<p class="popup-text__desc" style="color: ${
                    theme.isLightTheme ? "white" : "black"
                  };">${item?.shortDescription}</p>`
                : ""
            }
            ${
              item?.timings
                ? `<p class="popup-text-date" style="color: ${
                    theme.isLightTheme ? "white" : "black"
                  };">${item?.timings}</p>`
                : ""
            }
    </div>
            ${
              item?.bookingLink
                ? `<div  onclick="window.open('${
                    item.bookingLink
                  }', '_blank')" class="popup-text__book-now" style="color: ${
                    theme.isLightTheme ? "white" : "black"
                  }">Book now 
                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.15625 1.13672L8.59375 4.41797C8.69141 4.51562 8.75 4.63281 8.75 4.76953C8.75 4.88672 8.69141 5.00391 8.59375 5.10156L5.15625 8.38281C4.98047 8.55859 4.66797 8.55859 4.49219 8.36328C4.31641 8.1875 4.31641 7.875 4.51172 7.69922L7.10938 5.23828H0.46875C0.195312 5.23828 0 5.02344 0 4.76953C0 4.49609 0.195312 4.30078 0.46875 4.30078H7.10938L4.51172 1.82031C4.31641 1.64453 4.31641 1.33203 4.49219 1.15625C4.66797 0.960938 4.96094 0.960938 5.15625 1.13672Z" fill="${
                    theme.isLightTheme ? "white" : "black"
                  }"/>
                </svg>
              </div>`
                : ""
            }
            ${
              item?.website && theme.showDirections === true
                ? `<div onclick="window.open('${
                    item.website
                  }', '_blank')" class="popup-text__book-now" style="color: ${
                    theme.isLightTheme ? "white" : "black"
                  }">Learn more
                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.15625 1.13672L8.59375 4.41797C8.69141 4.51562 8.75 4.63281 8.75 4.76953C8.75 4.88672 8.69141 5.00391 8.59375 5.10156L5.15625 8.38281C4.98047 8.55859 4.66797 8.55859 4.49219 8.36328C4.31641 8.1875 4.31641 7.875 4.51172 7.69922L7.10938 5.23828H0.46875C0.195312 5.23828 0 5.02344 0 4.76953C0 4.49609 0.195312 4.30078 0.46875 4.30078H7.10938L4.51172 1.82031C4.31641 1.64453 4.31641 1.33203 4.49219 1.15625C4.66797 0.960938 4.96094 0.960938 5.15625 1.13672Z" fill="${
                    theme.isLightTheme ? "white" : "black"
                  }"/>
                </svg>
              </div>`
                : ""
            }


                ${
                  item?.website && theme.showDirections === false
                    ? `<div class="popup-text__book-now" style="color: ${
                        theme.isLightTheme ? "white" : "black"
                      };background-color: transparent;"
              onclick="window.open('${item.website}', '_blank')">Learn more
              
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.15625 1.13672L8.59375 4.41797C8.69141 4.51562 8.75 4.63281 8.75 4.76953C8.75 4.88672 8.69141 5.00391 8.59375 5.10156L5.15625 8.38281C4.98047 8.55859 4.66797 8.55859 4.49219 8.36328C4.31641 8.1875 4.31641 7.875 4.51172 7.69922L7.10938 5.23828H0.46875C0.195312 5.23828 0 5.02344 0 4.76953C0 4.49609 0.195312 4.30078 0.46875 4.30078H7.10938L4.51172 1.82031C4.31641 1.64453 4.31641 1.33203 4.49219 1.15625C4.66797 0.960938 4.96094 0.960938 5.15625 1.13672Z" fill="${
                    theme.isLightTheme ? "white" : "black"
                  }"/>
                </svg>
                </div>`
                    : ""
                }


          
      
          ${
            theme.showDirections
              ? `<div class="popup__button">          
              <button id="${PopupId}" class="popup__button-text">Get Direction</button>
              <button id="${googleDirectionId}" class="popup__button-img">
                <img src="${directionImage}" alt="icon" style="text-align: center;" />
              </button>
            </div>`
              : ""
          }
        </div>
      </div>
    </div>
  `;
};

export default Popup;
