import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
import "./MapComponent.css";
import { GeolocateControl } from "mapbox-gl";
import { config } from "./services/config";

import FitnessVillages from "./components/fitnessVillages/fitnessVillages";
import FitnessHubs from "./components/fitnessHubs/fitnessHubs";
import CyclingTracks from "./components/cyclingTracks/cyclingTracks";
import DubaiRides from "./components/dubaiRide/dubaiRide";
import DubaiRuns from "./components/dubaiRun/dubaiRun";
import Landmarks from "./components/landmarks/landmarks";
import Header from "./components/header/header";
import NearbyLocation from "./components/nearbyLocation/nearbyLocation";
import DubaiSups from "./components/dubiaSup/dubaiSup";

import { getAllData, getWelcomeData, getConfigurations } from "./services/api";
import FitnessFacilities from "./components/fitnessFacilities/fitnessFacilities";
import Events from "./components/events/events";
import JoggingTracks from "./components/joggingTracks/joggingTracks";

// Set your Mapbox access token
mapboxgl.accessToken = config.accessToken;

const MapComponent = () => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [mapdata, setMapData] = useState(null);
  const [mapConfig, setMapConfig] = useState(null);
  const [mapIcons, setMapIcons] = useState(null);
  const [filter, setFilter] = useState([]);
  const [activeDialog, setActiveDialog] = useState(null);
  const [welcomeData, setWelcomeData] = useState([]);
  const [logo, setLogo] = useState();
  const [logoLink, setLogoLink] = useState();

  const updateFilter = (newFilter) => {
    setFilter(newFilter);
  };

  // Function to open a new dialog and close the previous one
  const openDialog = (dialogName) => {
    setActiveDialog(dialogName);
  };

  const closeDialog = () => {
    setActiveDialog(null); // Close dialog
  };

  const addCoordinates = (coordinatesString) => {
    if (coordinatesString) {
      const coordinatesArray = coordinatesString
        .trim()
        .split("\n")
        .map((coord) => {
          const [lat, lng] = coord.split(",").map(Number);
          return [lng, lat];
        });
      return coordinatesArray;
    }
  };

  useEffect(() => {
    const loader = document.getElementById("loader");
    const fetchData = async () => {
      try {
        const config = await getConfigurations();
        setMapConfig(config?.data[0]?.headerItems);
        setLogo(config?.data[0]?.logo?.url);
        setLogoLink(config?.data[0]?.logoLink);

        const result = await getAllData();
        setMapData(result?.result?.data);
        setMapIcons(result?.result?.icons[0]);

        const welcomeMessage = await getWelcomeData();
        setWelcomeData(welcomeMessage?.data?.carousel);
      } catch (error) {
        // setError(error.message);
      } finally {
        loader.style.display = "none";
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!mapboxgl.accessToken) {
      return;
    }

    // Set RTL text plugin
    mapboxgl.setRTLTextPlugin("mapbox-gl-rtl-text.js");

    const initializeMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/standard",
      center: [55.28064154332981, 25.164994314755535], // Coordinates of Burj Khalifa
      zoom: 3,
      maxZoom: 16, // Set your desired maximum zoom level
      minZoom: 8,
      maxBounds: [
        [51.5, 22.5], // Southwest coordinates of UAE
        [56.5, 28.5], // Northeast coordinates of UAE
      ],
    });

    initializeMap.on("load", () => {
      const geolocate = new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      });

      initializeMap.addControl(geolocate, "top-left");
    });

    // Add navigation controls
    initializeMap.addControl(new mapboxgl.NavigationControl());

    initializeMap.dragRotate.disable();
    initializeMap.touchZoomRotate.disableRotation();

    initializeMap.on("style.load", () => {
      setMap(initializeMap); // Only set the map when style is loaded
    });
    // Cleanup function to remove map on component unmount
    return () => initializeMap.remove();
  }, []);

  useEffect(() => {
    if (!mapdata) return;
    let coordinateList = [];
    filter.forEach((options) => {
      mapdata[options].forEach((item) => {
        if (item?.rangeOfCoordinates) {
          let newCoord = addCoordinates(item.rangeOfCoordinates);
          coordinateList.push(...newCoord);
        } else if (item?.routeCoordinates) {
          let newCoord = addCoordinates(item.routeCoordinates);
          coordinateList.push(...newCoord);
        } else if (item?.supCoordinates) {
          let newCoord = addCoordinates(item.supCoordinates);
          coordinateList.push(...newCoord);
        } else if (item?.coordinates) {
          let newCoord = addCoordinates(item.coordinates);
          coordinateList.push(...newCoord);
        }

        if (item?.parking?.length > 0) {
          item?.parking.forEach((parking) => {
            let newCoord = addCoordinates(parking.coordinates);
            coordinateList.push(...newCoord);
          });
        }

        if (item?.gates?.length > 0) {
          item?.gates.forEach((gate) => {
            let newCoord = addCoordinates(gate.coordinates);
            coordinateList.push(...newCoord);
          });
        }
      });
    });

    if (coordinateList.length > 0) {
      // Calculate the bounds that include all the locations
      const bounds = new mapboxgl.LngLatBounds();

      // Extend the bounds to include each location
      coordinateList.forEach((location) => {
        bounds.extend(location);
      });

      // Adjust the map view to fit the bounds
      map.fitBounds(bounds, {
        padding: 20, // Optional padding around the bounds
      });
    }
  }, [filter, mapdata, map]);

  const getThemeByKey = (headerItemKey) => {
    const configItem = mapConfig.find(
      (item) => item.headerItemKey === headerItemKey
    );
    return configItem; // Return the theme or undefined if not found
  };

  return (
    <div>
      <div id="loader" className="fullpage-loader">
        <div className="loader"></div>
      </div>
      {mapConfig && map && welcomeData && mapdata && (
        <>
          <Header
            mapConfig={mapConfig}
            updateFilter={updateFilter}
            mapData={mapdata}
            map={map}
            activeDialog={activeDialog}
            openDialog={openDialog}
            closeDialog={closeDialog}
            welcomeData={welcomeData}
            logo={logo}
            logoLink={logoLink}
          />
        </>
      )}

      <div ref={mapContainer} style={{ width: "100%", height: "100vh" }}>
        {map && mapdata && mapConfig && (
          <>
            {filter.includes("track") && (
              <CyclingTracks
                map={map}
                cyclingTracks={mapdata["track"]}
                mapIcons={mapIcons?.cyclingTrack}
                theme={getThemeByKey("track")}
              />
            )}
            {filter.includes("running-track") && (
              <JoggingTracks
                map={map}
                runningTracks={mapdata["running-track"]}
                mapIcons={mapIcons?.runningTrack}
                theme={getThemeByKey("running-track")}
              />
            )}
            {filter.includes("fitness-station") && (
              <FitnessFacilities
                map={map}
                fitnessFacilities={mapdata["fitness-station"]}
                mapIcons={mapIcons?.fitnessStations}
                theme={getThemeByKey("fitness-station")}
              />
            )}
            {filter.includes("event") && (
              <Events
                map={map}
                events={mapdata["event"]}
                mapIcons={mapIcons?.eventTrack}
                theme={getThemeByKey("event")}
              />
            )}
            {filter.includes("fitness-hub") && (
              <FitnessHubs
                map={map}
                fitnessHubs={mapdata["fitness-hub"]}
                mapIcons={mapIcons?.fitnessHub}
                theme={getThemeByKey("fitness-hub")}
              />
            )}
            {filter.includes("fitness-village") && (
              <FitnessVillages
                map={map}
                fitnessVillages={mapdata["fitness-village"]}
                mapIcons={mapIcons?.fitnessVillage}
                theme={getThemeByKey("fitness-village")}
              />
            )}
            {filter.includes("dubai-sup") && (
              <DubaiSups
                map={map}
                dubaiSups={mapdata["dubai-sup"]}
                mapIcons={mapIcons?.dubaiSup}
                mapIconsPinpoint={mapIcons?.dubaiSupPinpoint}
                theme={getThemeByKey("dubai-sup")}
              />
            )}
            {filter.includes("dubai-ride") && (
              <DubaiRides
                map={map}
                dubaiRides={mapdata["dubai-ride"]}
                mapIcons={mapIcons?.dubaiRide}
                theme={getThemeByKey("dubai-ride")}
              />
            )}
            {filter.includes("dubai-run") && (
              <DubaiRuns
                map={map}
                dubaiRuns={mapdata["dubai-run"]}
                mapIcons={mapIcons?.dubaiRun}
                theme={getThemeByKey("dubai-run")}
              />
            )}
            {
              <Landmarks
                map={map}
                mapIcons={mapIcons?.landmarks}
                landmarks={mapdata["landmark"]}
                theme={getThemeByKey("landmark")}
              />
            }
            {mapdata && (
              <NearbyLocation
                mapData={mapdata}
                map={map}
                headerDetails={mapConfig}
                activeDialog={activeDialog}
                openDialog={openDialog}
                closeDialog={closeDialog}
                filterData={filter}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MapComponent;
