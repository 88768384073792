// JoggingTracks.js
import React, { useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { GeolocateControl } from "mapbox-gl";
import Popup from "../popup/popup";
import { config } from "../../services/config";
import { addRoute } from "../../services/getRoutes";
import {
  ValidateCoordinates,
  compareCoordinates,
} from "../../services/coordinatesValidator";
import { flyToLocation } from "../../services/flyTo";

const JoggingTracks = ({ map, runningTracks, mapIcons, theme }) => {
  const markersRef = useRef([]);
  const [userLocation, setUserLocation] = useState(null);
  const showDirection = false;
  let layerRef = useRef([]);

  const parseCoordinates = (coordinatesString) => {
    return coordinatesString
      .trim() // Remove extra spaces and newlines
      .split("\n") // Split by newline
      .map((coord) => {
        const [lat, lng] = coord.split(",").map(Number); // Split by comma and convert to numbers
        return [lng, lat]; // Reverse to [lng, lat] format
      });
  };

  React.useEffect(() => {
    if (map && runningTracks && runningTracks.length > 0) {
      // Clear previous markers
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current = [];
      const geolocate = new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      });

      geolocate.on("geolocate", (e) => {
        const lon = e.coords.longitude;
        const lat = e.coords.latitude;
        setUserLocation([lon, lat]);
      });
      runningTracks.forEach((track, index) => {
        if (
          track.showOnMap &&
          track.rangeOfCoordinates &&
          ValidateCoordinates(track.rangeOfCoordinates)
        ) {
          const trackCoordinates = parseCoordinates(track.rangeOfCoordinates);
          const sourceId = `track-${index}`;
          const layerId = `track-${index}`;

          // Add the track line
          if (!map.getSource(sourceId)) {
            map.addSource(sourceId, {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {
                  name: track.name,
                  description: track.description,
                },
                geometry: {
                  type: "LineString",
                  coordinates: trackCoordinates,
                },
              },
            });
          }

          if (!map.getLayer(layerId)) {
            map.addLayer({
              id: layerId,
              type: "line",
              source: sourceId,
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": track?.theme ? track?.theme : theme.theme,
                "line-width": theme.useThickLine ? 6 : 3,
              },
            });
            layerRef.current.push(layerId);
          } else {
            map.setLayoutProperty(layerId, "visibility", "visible");
            layerRef.current.push(layerId);
          }

          // Add start pointer
          const startEl = document.createElement("div");
          startEl.className = "jogging-marker";
          startEl.id = "jogging-marker";
          startEl.style.backgroundImage = `url(${
            config.baseUrl + mapIcons?.start?.icon?.url
          })`;
          startEl.style.width = "28px";
          startEl.style.height = "28px";
          startEl.style.backgroundSize = "100%";
          startEl.style.cursor = "pointer";

          const PopupId = `runBtn-${index}`;
          const googleDirectionId = `joggingGoogleBtn-${index}`;

          // Create the popup
          const popup = new mapboxgl.Popup({ offset: 35, closeButton: true })
            .setHTML(Popup({ theme, item: track, PopupId, googleDirectionId }))
            .on("open", () => {
              // Custom styling for the close button
              const closeButton = document.querySelector(
                ".mapboxgl-popup-close-button"
              );
              if (closeButton) {
                closeButton.textContent = ""; // Remove the "×"
              }
            });

          // Create and add the marker
          const startMarker = new mapboxgl.Marker(startEl)
            .setLngLat(trackCoordinates[0])
            .setPopup(popup) // sets a popup on this marker
            .addTo(map);
          markersRef.current.push(startMarker);

          map.on("click", () => {
            setTimeout(() => {
              const directionBtn = document.getElementById(PopupId);
              if (directionBtn) {
                directionBtn.addEventListener("click", () => {
                  popup.remove();
                  if (userLocation) {
                    addRoute(
                      userLocation,
                      trackCoordinates[0],
                      map,
                      `addRoute-11`
                    );
                  } else {
                    if ("geolocation" in navigator) {
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          const newUserLocation = [
                            position.coords.longitude,
                            position.coords.latitude,
                          ];
                          setUserLocation(newUserLocation);
                          addRoute(
                            newUserLocation,
                            trackCoordinates[0],
                            map,
                            `addRoute-11`
                          );
                        },
                        () => {
                          geolocate.trigger();
                        }
                      );
                    } else {
                    }
                  }
                });
              }
              const googleMapBtn = document.getElementById(googleDirectionId);
              if (googleMapBtn) {
                googleMapBtn.addEventListener("click", () => {
                  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&map_action=map&center&destination=${trackCoordinates[0][1]},${trackCoordinates[0][0]}&zoom=17`;
                  window.open(googleMapsUrl, "_blank");
                });
              }
            }, 0);
          });

          startEl.addEventListener("click", () => {
            if (trackCoordinates[0]) {
              flyToLocation(map, trackCoordinates[0]);
            }
          });

          if (
            !compareCoordinates(
              trackCoordinates[0],
              trackCoordinates[trackCoordinates.length - 1]
            )
          ) {
            const endEl = document.createElement("div");
            endEl.className = "jogging-marker";
            endEl.style.backgroundImage = `url(${
              config.baseUrl + mapIcons?.end?.icon?.url
            })`;
            endEl.style.width = "28px";
            endEl.style.height = "28px";
            endEl.style.backgroundSize = "100%";
            endEl.style.cursor = "pointer";

            const endMarker = new mapboxgl.Marker(endEl)
              .setLngLat(trackCoordinates[trackCoordinates.length - 1])
              .setPopup(
                new mapboxgl.Popup({ offset: 35, closeButton: true }).setHTML(
                  Popup({ theme, item: track, showDirection })
                )
              )
              .addTo(map);
            markersRef.current.push(endMarker);

            endEl.addEventListener("click", () => {
              const endCoordinates =
                trackCoordinates[trackCoordinates.length - 1];
              if (endCoordinates) {
                flyToLocation(map, endCoordinates);
              }
            });
          }
        }
      });
    }
    return () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current.forEach((layer) =>
        map.setLayoutProperty(layer, "visibility", "none")
      );
    };
  }, [map, runningTracks, theme, mapIcons, userLocation, showDirection]);

  return null;
};

export default JoggingTracks;
