import React, { useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import Popup from "../popup/popup";
import { config } from "../../services/config";
import { GeolocateControl } from "mapbox-gl";
import { addRoute } from "../../services/getRoutes";

import { ValidateCoordinates } from "../../services/coordinatesValidator";
import { flyToLocation } from "../../services/flyTo";

const DubaiSups = ({ map, dubaiSups, mapIcons, theme, mapIconsPinpoint }) => {
  const markersRef = useRef([]);
  const gatesRef = useRef([]);

  const [userLocation, setUserLocation] = useState(null);
  let layerRef = useRef([]);

  const parseCoordinates = (coordinatesString) => {
    return coordinatesString
      .trim() // Remove extra spaces and newlines
      .split("\n") // Split by newline
      .map((coord) => {
        const [lat, lng] = coord.split(",").map(Number); // Split by comma and convert to numbers
        return [lng, lat]; // Reverse to [lng, lat] format
      });
  };

  React.useEffect(() => {
    if (map && dubaiSups && dubaiSups.length > 0) {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current = [];
      const geolocate = new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      });

      geolocate.on("geolocate", (e) => {
        const lon = e.coords.longitude;
        const lat = e.coords.latitude;
        setUserLocation([lon, lat]);
      });

      dubaiSups.forEach((track, index) => {
        if (track.showOnMap && ValidateCoordinates(track.supCoordinates)) {
          const sourceId = `dubaiSups-${index}`;
          const layerId = `dubaiSups-${index}`;
          if (track.supCoordinates) {
            const trackCoordinates = parseCoordinates(track.supCoordinates);

            // Add the track line
            if (!map.getSource(sourceId)) {
              map.addSource(sourceId, {
                type: "geojson",
                data: {
                  type: "Feature",
                  properties: {
                    name: track.title,
                    description: track.shortDescription,
                  },
                  geometry: {
                    type: "LineString",
                    coordinates: trackCoordinates,
                  },
                },
              });
            }
          }

          if (!map.getLayer(layerId)) {
            map.addLayer({
              id: layerId,
              type: "line",
              source: sourceId,
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": track?.theme ? track?.theme : theme.theme,
                "line-width": theme.useThickLine ? 4 : 2,
              },
            });
            layerRef.current.push(layerId);
          } else {
            map.setLayoutProperty(layerId, "visibility", "visible");
            layerRef.current.push(layerId);
          }

          //kayakCoordinates
          if (
            track?.kayakCoordinates &&
            ValidateCoordinates(track?.kayakCoordinates)
          ) {
            const gateCoordinates = track?.kayakCoordinates.split(",");
            const gateCoordinatesNew = [gateCoordinates[1], gateCoordinates[0]];

            const rideGates = document.createElement("div");
            rideGates.className = "jogging-marker";
            rideGates.id = "jogging-marker";
            rideGates.style.backgroundImage = `url(${
              config.baseUrl + mapIconsPinpoint?.icon?.url
            })`;
            rideGates.style.width = "52px";
            rideGates.style.height = "52px";
            rideGates.style.backgroundSize = "100%";
            rideGates.style.cursor = "pointer";

            const PopupId = `supBtn-${index}`;
            const googleDirectionId = `supGoogleBtn-${index}`;

            // Create the popup
            const popup = new mapboxgl.Popup({ offset: 25, closeButton: true })
              .setHTML(
                Popup({ theme, item: track, PopupId, googleDirectionId })
              )
              .on("open", () => {
                // Custom styling for the close button
                const closeButton = document.querySelector(
                  ".mapboxgl-popup-close-button"
                );
                if (closeButton) {
                  closeButton.textContent = ""; // Remove the "×"
                }
              });

            // Create and add the marker
            const gatesMarker = new mapboxgl.Marker(rideGates)
              .setLngLat(gateCoordinatesNew)
              .setPopup(popup) // sets a popup on this marker
              .addTo(map);

            gatesMarker.getElement().addEventListener("click", () => {
              flyToLocation(map, gateCoordinatesNew); // Fly to kayak location
            });

            markersRef.current.push(gatesMarker);

            map.on("click", () => {
              setTimeout(() => {
                const directionBtn = document.getElementById(PopupId);
                if (directionBtn) {
                  directionBtn.addEventListener("click", () => {
                    popup.remove();
                    if (userLocation) {
                      addRoute(
                        userLocation,
                        gateCoordinatesNew,
                        map,
                        `addRoute-11`
                      );
                    } else {
                      if ("geolocation" in navigator) {
                        navigator.geolocation.getCurrentPosition(
                          (position) => {
                            const newUserLocation = [
                              position.coords.longitude,
                              position.coords.latitude,
                            ];
                            setUserLocation(newUserLocation);
                            addRoute(
                              newUserLocation,
                              gateCoordinatesNew,
                              map,
                              `addRoute-11`
                            );
                          },
                          () => {
                            alert(
                              "Please enable location services to get directions."
                            );
                            geolocate.trigger();
                          }
                        );
                      } else {
                        alert(
                          "Geolocation is not supported by your browser. Please use a modern browser to get directions."
                        );
                      }
                    }
                  });
                }
                const googleMapBtn = document.getElementById(googleDirectionId);
                if (googleMapBtn) {
                  googleMapBtn.addEventListener("click", () => {
                    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&map_action=map&center&destination=${gateCoordinatesNew[1]},${gateCoordinatesNew[0]}&zoom=17`;
                    window.open(googleMapsUrl, "_blank");
                  });
                }
              }, 0);
            });

            rideGates.addEventListener("click", () => {
              if (gateCoordinatesNew) {
                flyToLocation(map, gateCoordinatesNew);
              }
            });
          }
        }
      });
    }

    const addedGates = new Set(); // Track added gate markers

    const updateGatesVisibility = () => {
      if (map.getZoom() > 13) {
        dubaiSups.forEach((track, index) => {
          track?.parking.forEach((gate, index) => {
            const gateCoordinates = gate.coordinates.split(",");
            const gateCoordinatesNew = [gateCoordinates[1], gateCoordinates[0]];

            // Check if the gate has already been added
            if (!addedGates.has(gate.id)) {
              // Assuming gate.id is a unique identifier
              const gatesMarker = new mapboxgl.Marker({
                color: track?.theme ? track?.theme : theme.theme,
              })
                .setLngLat(gateCoordinatesNew)
                .addTo(map);
              gatesRef.current.push(gatesMarker);

              const label = document.createElement("div");
              label.className = "marker-label";
              label.textContent = gate.parkingName;
              label.style.color = "#000";
              label.style.position = "absolute";
              label.style.transform = "translate(-50%, 0)"; // Center the label horizontally
              label.style.fontSize = "15px";
              label.style.fontWeight = "bold";
              label.style.whiteSpace = "nowrap";
              label.style.top = "-45px";
              label.style.cursor = "pointer";

              const gateslabel = new mapboxgl.Marker(label)
                .setLngLat(gateCoordinatesNew)
                .addTo(map);
              gatesRef.current.push(gateslabel);

              // Add the gate ID to the set
              addedGates.add(gate.id);
            }
          });
        });
      } else {
        gatesRef.current.forEach((marker) => marker.remove());
        gatesRef.current = [];
        addedGates.clear();
      }
    };

    map.on("zoom", updateGatesVisibility);

    return () => {
      markersRef.current.forEach((marker) => marker?.remove());
      markersRef.current = [];
      layerRef.current.forEach((layer) =>
        map.setLayoutProperty(layer, "visibility", "none")
      );

      map.off("zoom", updateGatesVisibility); // Remove the zoom event listener

      gatesRef.current.forEach((marker) => marker.remove());
      gatesRef.current = [];
      addedGates.clear();
    };
  }, [map, dubaiSups, theme, mapIconsPinpoint?.icon?.url, userLocation]);

  return null;
};

export default DubaiSups;
