import React, { useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import Popup from "../popup/popup";
import { GeolocateControl } from "mapbox-gl";
import { config } from "../../services/config";
import { ValidateCoordinates } from "../../services/coordinatesValidator";
import { flyToLocation } from "../../services/flyTo";
import { addRoute } from "../../services/getRoutes";

const FitnessFacilities = ({ map, fitnessFacilities, mapIcons, theme }) => {
  const markersRef = useRef([]);
  const [userLocation, setUserLocation] = useState(null);
  const showDirection = false;

  React.useEffect(() => {
    const geolocate = new GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
    });

    geolocate.on("geolocate", (e) => {
      const lon = e.coords.longitude;
      const lat = e.coords.latitude;
      setUserLocation([lon, lat]);
    });
    if (map && fitnessFacilities && fitnessFacilities.length > 0) {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      fitnessFacilities.forEach((facility, index) => {
        if (
          facility.showOnMap &&
          facility.coordinates &&
          ValidateCoordinates(facility.coordinates)
        ) {
          const trackCoordinates = facility.coordinates.split(",").map(Number);
          const facilityCoordinatesNew = [
            trackCoordinates[1],
            trackCoordinates[0],
          ];

          // Create a DOM element for the marker
          const el = document.createElement("div");
          el.className = "marker";
          el.style.backgroundImage = `url(${
            config.baseUrl + mapIcons?.icon?.url
          })`;
          el.style.width = "28px";
          el.style.height = "28px";
          el.style.backgroundSize = "100%";
          el.style.cursor = "pointer";

          // Create the popup
          const popup = new mapboxgl.Popup({ offset: 35, closeButton: true })
            .setHTML(Popup({ theme, item: facility, showDirection }))
            .on("open", () => {
              // Optionally, style the close button here
            });

          // Create and add the marker
          const landmarkMarker = new mapboxgl.Marker(el)
            .setLngLat(facilityCoordinatesNew) // Set marker at the first point of the polygon
            .setPopup(popup) // Set popup on this marker
            .addTo(map);

          markersRef.current.push(landmarkMarker);

          map.on("click", () => {
            setTimeout(() => {
              const directionBtn = document.getElementById(
                `directionBtn-${facility.id}`
              );
              if (directionBtn) {
                directionBtn.addEventListener("click", () => {
                  popup.remove();
                  if (userLocation) {
                    addRoute(
                      userLocation,
                      facilityCoordinatesNew,
                      map,
                      `addRoute-11`
                    );
                  } else {
                    if ("geolocation" in navigator) {
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          const newUserLocation = [
                            position.coords.longitude,
                            position.coords.latitude,
                          ];
                          setUserLocation(newUserLocation);
                          addRoute(
                            newUserLocation,
                            trackCoordinates[0],
                            map,
                            `addRoute-11`
                          );
                        },
                        () => {
                          alert(
                            "Please enable location services to get directions."
                          );
                          geolocate.trigger();
                        }
                      );
                    } else {
                      alert(
                        "Geolocation is not supported by your browser. Please use a modern browser to get directions."
                      );
                    }
                  }
                });
              }
              const googleMapBtn = document.getElementById(
                `googleMapBtn-${facility.id}`
              );
              if (googleMapBtn) {
                googleMapBtn.addEventListener("click", () => {
                  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&map_action=map&center&destination=${trackCoordinates[0][1]},${trackCoordinates[0][0]}&zoom=17`;
                  window.open(googleMapsUrl, "_blank");
                });
              }
            }, 0);
          });

          // Add click event to marker
          el.addEventListener("click", () => {
            popup.addTo(map);
            // Center the map on the polygon's first point
            flyToLocation(map, facilityCoordinatesNew);
          });
        }
      });
    }
    return () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
    };
  }, [
    map,
    fitnessFacilities,
    mapIcons?.icon?.url,
    theme,
    showDirection,
    userLocation,
  ]);

  return null;
};

export default FitnessFacilities;
