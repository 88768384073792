// FitnessHubs.js
import React, { useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import Popup from "../popup/popup";
import { GeolocateControl } from "mapbox-gl";
import { config } from "../../services/config";
import { ValidateCoordinates } from "../../services/coordinatesValidator";
import { flyToLocation } from "../../services/flyTo";
import { addRoute } from "../../services/getRoutes";

const FitnessHubs = ({ map, fitnessHubs, mapIcons, theme }) => {
  const markersRef = useRef([]);
  const [userLocation, setUserLocation] = useState(null);

  React.useEffect(() => {
    if (map && fitnessHubs && fitnessHubs.length > 0) {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];

      const geolocate = new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      });

      geolocate.on("geolocate", (e) => {
        const lon = e.coords.longitude;
        const lat = e.coords.latitude;
        setUserLocation([lon, lat]);
      });

      fitnessHubs.forEach((fitness, index) => {
        if (
          fitness.showOnMap &&
          fitness.coordinates &&
          ValidateCoordinates(fitness.coordinates)
        ) {
          const landmarkCoordinates = fitness.coordinates
            .split(",")
            .map(Number);
          const fitnessCoordinatesNew = [
            landmarkCoordinates[1],
            landmarkCoordinates[0],
          ];
          // Create a DOM element for the marker
          const el = document.createElement("div");
          el.className = "gym-marker";
          el.style.backgroundImage = `url(${
            config.baseUrl + mapIcons?.icon?.url
          })`;
          el.style.width = "28px";
          el.style.height = "28px";
          el.style.backgroundSize = "100%";
          el.style.cursor = "pointer";

          // Create the popup
          const PopupId = `gymBtn-${index}`;
          const googleDirectionId = `fitnessHubGoogleBtn-${index}`;

          const popup = new mapboxgl.Popup({ offset: 25 })
            .setHTML(
              Popup({ theme, item: fitness, PopupId, googleDirectionId })
            )
            .on("open", () => {
              const closeButton = document.querySelector(
                ".mapboxgl-popup-close-button"
              );
              if (closeButton) {
                closeButton.textContent = ""; // Remove the "×"
              }
              // Change to your desired color
              // closeButton.style.color = "#FFFFFF";
              // closeButton.style.fontSize = "18px";
              // closeButton.style.padding = "16px 16px 0px 0px ";
            });

          // Create and add the marker
          const gymMarker = new mapboxgl.Marker(el)
            .setLngLat(fitnessCoordinatesNew)
            .setPopup(popup) // sets a popup on this marker
            .addTo(map);

          markersRef.current.push(gymMarker);

          map.on("click", () => {
            setTimeout(() => {
              const directionBtn = document.getElementById(PopupId);
              if (directionBtn) {
                directionBtn.addEventListener("click", () => {
                  popup.remove();
                  if (userLocation) {
                    addRoute(
                      userLocation,
                      fitnessCoordinatesNew,
                      map,
                      `addRoute-11`
                    );
                  } else {
                    if ("geolocation" in navigator) {
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          const newUserLocation = [
                            position.coords.longitude,
                            position.coords.latitude,
                          ];
                          setUserLocation(newUserLocation);
                          addRoute(
                            newUserLocation,
                            fitnessCoordinatesNew,
                            map,
                            `addRoute-11`
                          );
                        },
                        () => {
                          alert(
                            "Please enable location services to get directions."
                          );
                          geolocate.trigger();
                        }
                      );
                    } else {
                      alert(
                        "Geolocation is not supported by your browser. Please use a modern browser to get directions."
                      );
                    }
                  }
                });
              }
              const googleMapBtn = document.getElementById(googleDirectionId);
              if (googleMapBtn) {
                googleMapBtn.addEventListener("click", () => {
                  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&map_action=map&center&destination=${fitnessCoordinatesNew[1]},${fitnessCoordinatesNew[0]}&zoom=17`;
                  window.open(googleMapsUrl, "_blank");
                });
              }
            }, 0);
          });

          // Add click event to marker
          el.addEventListener("click", () => {
            popup.addTo(map);
          });
          el.addEventListener("click", () => {
            // Add popup to the map
            popup.addTo(map);

            if (fitnessCoordinatesNew && fitnessCoordinatesNew.length === 2) {
              flyToLocation(map, fitnessCoordinatesNew);
            } else {
              // console.error('Invalid coordinates for map.flyTo:', landmarkCoordinatesNew);
            }
          });
        }
      });
    }
    return () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
    };
  }, [map, fitnessHubs]);

  return null;
};

export default FitnessHubs;
