import React, { useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import Popup from "../popup/popup";
import { GeolocateControl } from "mapbox-gl";
import { config } from "../../services/config";
import { addRoute } from "../../services/getRoutes";
import {
  ValidateCoordinates,
  compareCoordinates,
} from "../../services/coordinatesValidator";
import { flyToLocation } from "../../services/flyTo";
const Events = ({ map, events, mapIcons, theme }) => {
  const markersRef = useRef([]);
  const [userLocation, setUserLocation] = useState(null);
  const showDirection = false;
  let layerRef = useRef([]);
  const parseCoordinates = (coordinatesString) => {
    return coordinatesString
      .trim() // Remove extra spaces and newlines
      .split("\n") // Split by newline
      .map((coord) => {
        const [lat, lng] = coord.split(",").map(Number); // Split by comma and convert to numbers
        return [lng, lat]; // Reverse to [lng, lat] format
      });
  };

  React.useEffect(() => {
    if (map && events && events.length > 0) {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current = [];
      const geolocate = new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      });

      geolocate.on("geolocate", (e) => {
        const lon = e.coords.longitude;
        const lat = e.coords.latitude;
        setUserLocation([lon, lat]);
      });
      let counter = 0;
      events.forEach((event, index) => {
        if (event.showOnMap && event.coordinates) {
          let eventCoordinatesNew;
          if (
            event.coordinates &&
            event.showOnMap &&
            ValidateCoordinates(event.coordinates)
          ) {
            eventCoordinatesNew = parseCoordinates(event.coordinates);
            const sourceId = `event-${counter}`;
            const layerId = `event-${counter}`;
            counter++;
            // Add the event line
            if (!map.getSource(sourceId)) {
              map.addSource(sourceId, {
                type: "geojson",
                data: {
                  type: "Feature",
                  properties: {
                    name: event.name,
                    description: event.description,
                  },
                  geometry: {
                    type: "LineString",
                    coordinates: eventCoordinatesNew,
                  },
                },
              });
            }

            if (!map.getLayer(layerId)) {
              map.addLayer({
                id: layerId,
                type: "line",
                source: sourceId,
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  "line-color": event.theme ? event.theme : theme.theme,
                  "line-width": theme.useThickLine ? 4 : 2,
                },
              });
              layerRef.current.push(layerId);
            } else {
              map.setLayoutProperty(layerId, "visibility", "visible");
              layerRef.current.push(layerId);
            }

            // Add start pointer
            const startEl = document.createElement("div");
            startEl.className = "jogging-marker";
            startEl.id = "jogging-marker";
            startEl.style.backgroundImage = `url(${
              config.baseUrl + mapIcons?.start?.icon?.url
            })`;
            startEl.style.width = "28px";
            startEl.style.height = "28px";
            startEl.style.backgroundSize = "100%";
            startEl.style.cursor = "pointer";

            // Create the popup
            const PopupId = `eventBtn-${index}`;
            const googleDirectionId = `eventGoogleBtn-${index}`;

            const popup = new mapboxgl.Popup({ offset: 35, closeButton: true })
              .setHTML(
                Popup({ theme, item: event, PopupId, googleDirectionId })
              )
              .on("open", () => {
                // Custom styling for the close button
                const closeButton = document.querySelector(
                  ".mapboxgl-popup-close-button"
                );
                if (closeButton) {
                  closeButton.textContent = ""; // Remove the "×"
                }
              });

            // Create and add the marker
            const eventMarker = new mapboxgl.Marker(startEl)
              .setLngLat(eventCoordinatesNew[0])
              .setPopup(popup) // sets a popup on this marker
              .addTo(map);
            markersRef.current.push(eventMarker);

            map.on("click", () => {
              setTimeout(() => {
                const directionBtn = document.getElementById(PopupId);
                if (directionBtn) {
                  directionBtn.addEventListener("click", () => {
                    popup.remove();
                    if (userLocation) {
                      addRoute(
                        userLocation,
                        eventCoordinatesNew[0],
                        map,
                        `addRoute-11`
                      );
                    } else {
                      if ("geolocation" in navigator) {
                        navigator.geolocation.getCurrentPosition(
                          (position) => {
                            const newUserLocation = [
                              position.coords.longitude,
                              position.coords.latitude,
                            ];
                            setUserLocation(newUserLocation);
                            addRoute(
                              newUserLocation,
                              eventCoordinatesNew[0],
                              map,
                              `addRoute-11`
                            );
                          },
                          () => {
                            alert(
                              "Please enable location services to get directions."
                            );
                            geolocate.trigger();
                          }
                        );
                      } else {
                        alert(
                          "Geolocation is not supported by your browser. Please use a modern browser to get directions."
                        );
                      }
                    }
                  });
                }
                const googleMapBtn = document.getElementById(googleDirectionId);
                if (googleMapBtn) {
                  googleMapBtn.addEventListener("click", () => {
                    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&map_action=map&center&destination=${eventCoordinatesNew[0][1]},${eventCoordinatesNew[0][0]}&zoom=17`;
                    window.open(googleMapsUrl, "_blank");
                  });
                }
              }, 0);
            });

            startEl.addEventListener("click", () => {
              flyToLocation(map, eventCoordinatesNew[0]);
            });

            // Add end pointer
            if (
              !compareCoordinates(
                eventCoordinatesNew[0],
                eventCoordinatesNew[eventCoordinatesNew.length - 1]
              )
            ) {
              const endEl = document.createElement("div");
              endEl.className = "jogging-marker";
              endEl.style.backgroundImage = `url(${
                config.baseUrl + mapIcons?.end?.icon.url
              })`;
              endEl.style.width = "28px";
              endEl.style.height = "28px";
              endEl.style.backgroundSize = "100%";
              endEl.style.cursor = "pointer";

              const endMarker = new mapboxgl.Marker(endEl)
                .setLngLat(eventCoordinatesNew[eventCoordinatesNew.length - 1])
                .setPopup(
                  new mapboxgl.Popup({ offset: 35, closeButton: true }).setHTML(
                    Popup({ theme, item: event, showDirection })
                  )
                )
                .addTo(map);
              markersRef.current.push(endMarker);
              endEl.addEventListener("click", () => {
                flyToLocation(
                  map,
                  eventCoordinatesNew[eventCoordinatesNew.length - 1]
                );
              });
            }
          } else {
            const eventCoordinates = event.coordinates.split(",").map(Number);
            eventCoordinatesNew = [eventCoordinates[1], eventCoordinates[0]];
            // Create a DOM element for the marker
            const el = document.createElement("div");
            el.className = "marker";
            el.style.backgroundImage = `url(${
              config.baseUrl + mapIcons?.start?.icon?.url
            })`;
            el.style.width = "28px";
            el.style.height = "28px";
            el.style.backgroundSize = "100%";
            el.style.cursor = "pointer";

            // Create the popup
            const popup = new mapboxgl.Popup({ offset: 25, closeButton: true })
              .setHTML(Popup({ theme, item: event, showDirection }))
              .on("open", () => {
                // Custom styling for the close button
                const closeButton = document.querySelector(
                  ".mapboxgl-popup-close-button"
                );
                if (closeButton) {
                  closeButton.textContent = ""; // Remove the "×"
                }
              });

            // Create and add the marker
            const eventMarker = new mapboxgl.Marker(el)
              .setLngLat(eventCoordinatesNew)
              .setPopup(popup) // sets a popup on this marker
              .addTo(map);

            markersRef.current.push(eventMarker);

            // Add click event to marker
            el.addEventListener("click", () => {
              popup.addTo(map);
              flyToLocation(map, eventCoordinatesNew);
            });
          }
        }
      });
    }
    return () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current.forEach((layer) =>
        map.setLayoutProperty(layer, "visibility", "none")
      );
    };
  }, [
    map,
    events,
    mapIcons?.start?.icon?.url,
    mapIcons?.end?.icon.url,
    theme,
    userLocation,
    showDirection,
  ]);

  return null;
};

export default Events;
