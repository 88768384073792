// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arena-marker {
	background-size: cover;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	cursor: pointer;
  
}

.mapboxgl-popup {
    max-width: 300px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    z-index: 4;
  }
  
  .mapboxgl-popup-content {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    padding: 15px;
  }
  
  .mapboxgl-popup-close-button {
    font-size: 16px;
    color: #555;
  }
  
  .popup-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
  }
  
  .popup-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  
  .popup-button {
    background-color: #0078A8;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;
  }
  
  .popup-button:hover {
    background-color: #005d84;
  }


.mapboxgl-popup-content {
    padding: 0px !important; /* Set padding to 0 */
}
.mapboxgl-popup-tip{
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/MapComponent.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,eAAe;;AAEhB;;AAEA;IACI,gBAAgB;IAChB,8DAA8D;IAC9D,UAAU;EACZ;;EAEA;IACE,0CAA0C;IAC1C,mBAAmB;IACnB,qCAAqC;IACrC,aAAa;EACf;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;IACX,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;;AAGF;IACI,uBAAuB,EAAE,qBAAqB;AAClD;AACA;EACE,aAAa;AACf","sourcesContent":[".arena-marker {\n\tbackground-size: cover;\n\twidth: 40px;\n\theight: 40px;\n\tborder-radius: 50%;\n\tcursor: pointer;\n  \n}\n\n.mapboxgl-popup {\n    max-width: 300px;\n    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;\n    z-index: 4;\n  }\n  \n  .mapboxgl-popup-content {\n    background-color: rgba(255, 255, 255, 0.9);\n    border-radius: 10px;\n    box-shadow: 0 1px 2px rgba(0,0,0,0.1);\n    padding: 15px;\n  }\n  \n  .mapboxgl-popup-close-button {\n    font-size: 16px;\n    color: #555;\n  }\n  \n  .popup-header {\n    font-size: 16px;\n    font-weight: bold;\n    margin-bottom: 10px;\n    color: #333;\n    border-bottom: 1px solid #ddd;\n    padding-bottom: 5px;\n  }\n  \n  .popup-description {\n    font-size: 14px;\n    color: #666;\n    margin-bottom: 10px;\n  }\n  \n  .popup-button {\n    background-color: #0078A8;\n    color: white;\n    border: none;\n    padding: 8px 12px;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 12px;\n    transition: background-color 0.3s;\n  }\n  \n  .popup-button:hover {\n    background-color: #005d84;\n  }\n\n\n.mapboxgl-popup-content {\n    padding: 0px !important; /* Set padding to 0 */\n}\n.mapboxgl-popup-tip{\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
