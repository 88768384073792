import { useEffect } from 'react';

function useLandscapeClass() {
    useEffect(() => {
        const handleOrientationChange = () => {
            if (window.innerWidth > window.innerHeight && window.innerWidth <= 992) {
                // Landscape orientation on mobile
                document.body.classList.add('landscape-mode');
            } else {
                // Portrait orientation or non-mobile screen
                document.body.classList.remove('landscape-mode');
            }
        };

        // Check orientation on mount
        handleOrientationChange();

        // Add event listener for window resize
        window.addEventListener('resize', handleOrientationChange);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleOrientationChange);
    }, []);
}

export default useLandscapeClass;
