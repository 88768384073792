// src/services/api.js
import axios from "axios";
import { config } from "./config";

export const getAllData = async () => {
  // Show loader
  const loader = document.getElementById("loader");
  loader.style.display = "flex";
  try {
    const response = await axios.get(`${config.baseUrl}/api/allwithcondition`);
    return response.data;
  } catch (error) {
    loader.style.display = "none";
    throw error;
  }
};

export const getConfigurations = async () => {
  // Show loader
  const loader = document.getElementById("loader");
  loader.style.display = "flex";
  try {
    const response = await axios.get(`${config.baseUrl}/api/configurations`);
    return response.data;
  } catch (error) {
    loader.style.display = "none";
    throw error;
  }
};

export const getWelcomeData = async () => {
  // Show loader
  const loader = document.getElementById("loader");
  loader.style.display = "flex";
  try {
    const response = await axios.get(`${config.baseUrl}/api/welcome-message`);
    return response.data;
  } catch (error) {
    loader.style.display = "none";
    throw error;
  }
};
