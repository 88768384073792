import React, { useEffect, useState } from "react";
import "../../assets/scss/style.scss";
import filterImage from "../../assets/Filter-Icon.svg";
import searchImage from "../../assets/search.svg";
import searchImageIcon from "../../assets/search-icon.svg";
import clockIcon from "../../assets/clock-icon.svg";
import closePopup from "../../assets/close-popup.svg";
import { useSwipeable } from "react-swipeable";
import { config } from "../../services/config";
import popupClose from "../../assets/popup-close.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Header({
  mapConfig,
  updateFilter,
  mapData,
  map,
  activeDialog,
  openDialog,
  closeDialog,
  welcomeData,
  logo,
  logoLink,
}) {
  const [filter, setFilter] = useState([]);
  const [activeItemId, setActiveItemId] = useState(1);
  const [swipedTop, setswipedTop] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterMapData, setFilterMapData] = useState([]);
  const [activeTheme, setActiveTheme] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false); // State to track filter visibility
  const [itemNotFound, setItemNotFound] = useState(false);
  const [filterTopLabel, setFilterTopLabel] = useState([]);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  // State to store search results
  const [searchResultData, setSearchResultData] = useState([]);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const toggleFilter = () => {
    handleOpenDialog("filterDialog");
    setIsFilterOpen(!isFilterOpen); // Toggle the state
    setIsSearchOpen(false);
  };
  const [isSearchOpen, setIsSearchOpen] = useState(false); // State to track Search visibility
  const toggleSearch = () => {
    handleOpenDialog("searchDialog");
    setIsSearchOpen(!isSearchOpen); // Toggle the state
    setIsFilterOpen(false);
    if (isSearchOpen) {
      setSearchValue("");
      setSearchResultData([]);
    } else {
      setItemNotFound(false);
    }
    if (toggleFilter) {
      setSearchValue("");
      setSearchResultData([]);
    } else {
      setItemNotFound(false);
    }
  };

  // global dialog state
  const handleOpenDialog = (dialogName) => {
    if (activeDialog !== dialogName) {
      openDialog(dialogName); // Open the new dialog
    } else {
      openDialog(null);
    }
  };

  // setup ref for your usage
  const myRef = React.useRef();

  const refPassthrough = (el) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set myRef el so you can access it yourself
    myRef.current = el;
  };

  const handlers = useSwipeable({
    onSwiping: (SwipeEventData) => {
      if (SwipeEventData.dir === "Up") {
        setswipedTop("active");
      } else if (SwipeEventData.dir === "Down") {
        setswipedTop("");
      }
    },
  });

  const handleItemClick = (item) => {
    let updatedFilter;
    if (filter.includes(item.headerItemKey)) {
      updatedFilter = filter.filter((option) => option !== item.headerItemKey);
    } else {
      updatedFilter = [...filter, item.headerItemKey];
    }

    //filter top label filter and set the active onw only
    const filteredHeaderItems = mapConfig.filter((item) =>
      updatedFilter.includes(item.headerItemKey)
    );
    setFilterTopLabel(filteredHeaderItems);

    //check and set filter active active item
    const checkfilterActiveItem = filteredHeaderItems.filter(
      (item) => item.id === activeItemId
    );
    if (checkfilterActiveItem.length > 0) {
    } else if (filteredHeaderItems.length > 0) {
      setActiveItemId(filteredHeaderItems[0].id);
      setFilterMapData(mapData[filteredHeaderItems[0].headerItemKey]);
      setActiveTheme(filteredHeaderItems[0].theme);
    } else {
      setFilterMapData([]);
      setActiveTheme("");
      setActiveItemId("");
    }

    setFilter(updatedFilter);
    updateFilter(updatedFilter);
  };

  const filterOption = (item) => {
    setActiveItemId(item.id); // Set the active item ID
    let options = mapData[item.headerItemKey].filter(
      (item) => item.showOnMap === true
    );
    setFilterMapData(options);
    setActiveTheme(item.theme);
  };

  const focusMap = (item) => {
    let coordinates = "";
    if (item?.routeCoordinates) {
      coordinates = item?.routeCoordinates;
    } else if (item?.supCoordinates) {
      coordinates = item?.supCoordinates;
    } else if (item?.coordinates) {
      coordinates = item.coordinates;
    } else if (item?.rangeOfCoordinates) {
      coordinates = item.rangeOfCoordinates;
    }

    if (!coordinates) {
      return;
    }

    const coordinatePairs = coordinates.split("\n");
    const [lat, lng] = coordinatePairs[0]
      .split(",")
      .map((coord) => parseFloat(coord));

    map.flyTo({
      center: [lng, lat], // Mapbox uses [lng, lat] format
      zoom: 14, // Adjust the zoom level as needed
      essential: true, // This animation is considered essential
      offset: [0, 150],
    });
    openDialog(null);
  };

  useEffect(() => {
    let defaultData = [];
    mapConfig.forEach((item, index) => {
      if (item.showOnMap) {
        defaultData.push(item.headerItemKey);
      }
      if (index === 0) {
        setActiveTheme(item.theme);
      }
    });

    setFilterTopLabel(mapConfig);
    setFilter(defaultData);
    updateFilter(defaultData);
    setFilterMapData(mapData[defaultData[0]]);

    const hasSeenPopup = localStorage.getItem("hasSeenPopup");
    if (!hasSeenPopup) {
      setIsPopupVisible(true); // Show the popup
      localStorage.setItem("hasSeenPopup", "true"); // Set the flag in localStorage
    } else {
      setIsPopupVisible(false);
    }
  }, []);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    let newSearchData = [...(mapData["landmark"] || [])];
    filter.forEach((options) => {
      newSearchData.push(...mapData[options]);
    });

    if (e.target.value) {
      const searchTerm = e.target.value.toLowerCase(); // Convert to lowercase for case-insensitive search
      const filteredData = newSearchData.filter((item) =>
        item.title.toLowerCase().includes(searchTerm)
      );
      setSearchResultData(filteredData); // Update search results
      if (filteredData.length > 0) {
        setItemNotFound(false);
      } else {
        setItemNotFound(true);
      }
    } else {
      setSearchResultData([]);
    }
  };

  return (
    <header className="header">
      <div className="header__container">
        <div className="header__wraper">
          <div className="header__col">
            <a href={logoLink}>
              <img
                className="header__logo"
                src={config.baseUrl + logo}
                alt="Logo"
              />
            </a>
          </div>
          <div className="header__col mobile-col">
            <div
              className={`header__nav mobile-nav ${swipedTop}`}
              {...handlers}
              ref={refPassthrough}
            >
              <div className="drag-handle">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.77344 0.726562L16.5234 7.47656C16.9805 7.89844 16.9805 8.63672 16.5234 9.05859C16.1016 9.51562 15.3633 9.51562 14.9414 9.05859L9 3.11719L3.02344 9.05859C2.60156 9.51562 1.86328 9.51562 1.44141 9.05859C0.984375 8.63672 0.984375 7.89844 1.44141 7.47656L8.19141 0.726562C8.61328 0.269531 9.35156 0.269531 9.77344 0.726562ZM16.5234 14.2266C16.9805 14.6484 16.9805 15.3867 16.5234 15.8086C16.1016 16.2656 15.3633 16.2656 14.9414 15.8086L9 9.86719L3.02344 15.8086C2.60156 16.2656 1.86328 16.2656 1.44141 15.8086C0.984375 15.3867 0.984375 14.6484 1.44141 14.2266L8.19141 7.47656C8.61328 7.01953 9.35156 7.01953 9.77344 7.47656L16.5234 14.2266Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="header__list">
                {mapConfig
                  .filter((item) => item.showOnMap)
                  .map((item) => (
                    <div
                      className={`header__item ${
                        filter.includes(item.headerItemKey) ? "active" : ""
                      } ${item.isLightTheme ? "light" : "dark"}`}
                      style={{
                        color: item.theme,
                        backgroundColor:
                          hoveredItemId === item.id
                            ? item.hoverTheme // Background color on hover
                            : filter.includes(item.headerItemKey)
                            ? item.theme
                            : "transparent",
                      }}
                      key={item.id}
                      onClick={() => handleItemClick(item)}
                      onMouseEnter={() => setHoveredItemId(item.id)} // Set hovered item ID
                      onMouseLeave={() => setHoveredItemId(null)}
                    >
                      <img
                        className="header__icon-img"
                        src={`${config.baseUrl + item?.headerIcon?.url}`}
                        alt={item.headerTitle}
                      />
                      <div className="header__title">{item.headerTitle}</div>
                      <div
                        style={{ backgroundColor: item.theme }}
                        className="overlay-color"
                      >
                        {" "}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="header__col">
            <div className="header__filters">
              <div className="header__seacrch" onClick={toggleSearch}>
                <img src={searchImage} alt="Search" />
              </div>
              <div className="header__filter" onClick={toggleFilter}>
                <img src={filterImage} alt="Filter" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`open-filter ${
          activeDialog === "filterDialog" ? "open" : ""
        }`}
      >
        <div className="open-filter__wrap">
          <div className="close-popup" onClick={toggleFilter}>
            <img src={closePopup} alt="" />
          </div>
          <div className="open-filter__wrapper">
            <div className="open-filter__item-list">
              {filterTopLabel.filter((item) => item.showOnMap).length > 0 ? (
                filterTopLabel
                  .filter((item) => item.showOnMap)
                  .map((item) => (
                    <div
                      className={`open-filter__item ${
                        activeItemId === item.id ? "active" : ""
                      } ${item.isLightTheme ? "light" : "dark"} ${
                        !filter.includes(item.headerItemKey) ? "disabled" : ""
                      }`}
                      style={{
                        color: item.theme,
                        backgroundColor:
                          activeItemId === item.id ? item.theme : "transparent",
                      }}
                      key={item.id}
                      onClick={() => filterOption(item)}
                    >
                      <div className="open-filter__title">
                        {item.headerTitle}
                      </div>
                      <div
                        style={{ backgroundColor: item.theme }}
                        className="overlay-color"
                      >
                        {" "}
                      </div>
                    </div>
                  ))
              ) : (
                <div className="not-found-error">No filter available</div>
              )}
            </div>
            {filterMapData.length > 0 && (
              <div className="vetical-list">
                {filterMapData
                  .filter(
                    (item) =>
                      item?.rangeOfCoordinates ||
                      item?.routeCoordinates ||
                      item?.supCoordinates ||
                      item?.coordinates
                  )
                  .map((item, index) => (
                    <div
                      className="vetical-list__item"
                      key={item.id || index} // Add a unique key here
                      onClick={() => focusMap(item)}
                    >
                      <div className="vetical-list__cont">
                        <div className="vetical-list__icon">
                          {/* <img src={markImage} alt="" /> */}

                          <svg
                            width="19"
                            height="22"
                            viewBox="0 0 19 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.23406 3.23406L3.23406 3.23406C6.54614 -0.0780182 11.9161 -0.0780247 15.2282 3.23406C18.5403 6.54612 18.5403 11.9161 15.2282 15.2281L9.23111 21.2252L3.23406 15.2281L2.70373 15.7585L3.23406 15.2281C-0.0780206 11.9161 -0.0780181 6.54612 3.23406 3.23406ZM9.33516 4.09883C6.6338 4.09883 4.4439 6.28871 4.4439 8.99007C4.4439 11.6914 6.6338 13.8813 9.33516 13.8813C12.0365 13.8813 14.2264 11.6914 14.2264 8.99007C14.2264 6.28871 12.0365 4.09883 9.33516 4.09883Z"
                              fill={activeTheme}
                              stroke={activeTheme}
                              strokeWidth="1.5"
                            />
                          </svg>
                        </div>
                        <div className="vetical-list__titile">{item.title}</div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={`open-search ${
          activeDialog === "searchDialog" ? "open" : ""
        } ${
          !searchResultData.length && itemNotFound ? "search-not-found" : ""
        } ${searchResultData.length > 0 ? "search-result" : ""}`}
      >
        <div className="open-filter__wrap">
          <div className="close-popup" onClick={toggleSearch}>
            <img src={closePopup} alt="" />
          </div>

          <div className="open-search__wraper">
            <div className="search-box">
              <input
                type="search"
                className="search-box__input"
                placeholder="Search"
                value={searchValue}
                onChange={handleSearch}
              />
              <img src={searchImageIcon} alt="" />
            </div>
            {searchResultData.length > 0 && (
              <div className="vetical-list">
                {searchResultData
                  .filter(
                    (item) =>
                      item?.rangeOfCoordinates ||
                      item?.routeCoordinates ||
                      item?.supCoordinates ||
                      item?.coordinates
                  )
                  .map((item) => (
                    <div
                      className="vetical-list__item"
                      key={item?.documentId}
                      onClick={() => focusMap(item)}
                    >
                      <div className="vetical-list__cont">
                        <div className="vetical-list__icon">
                          <img src={clockIcon} alt="" />
                        </div>
                        <div className="vetical-list__titile">{item.title}</div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {!searchResultData.length && itemNotFound && (
              <div className="vetical-list">
                <div className="vetical-list__item">
                  <p>No item found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <div className="popup">
          <div className="popup__wrapper">
            <img
              className="popup__close"
              src={popupClose}
              alt="Close"
              onClick={handleClosePopup}
            />

            <Carousel
              showStatus={false}
              swipeable={true}
              showIndicators={welcomeData?.length > 1}
              emulateTouch={true}
              showThumbs={false}
            >
              {welcomeData.map((item) => (
                <div>
                  <h3 className="popup__title">{item.title}</h3>
                  <p className="popup__desc">{item.description}</p>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
