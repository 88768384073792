export const flyToLocation = (map, coordinates, zoom = 14, speed = 1.4, offset = [0, 150]) => {
    const isMobile = window.innerWidth <= 1024;
    if (isMobile && map && coordinates) {
      map.flyTo({
        center: coordinates,
        zoom: zoom,
        speed: speed,
        essential: true,
        offset: offset,
      });
    } else {
      // console.error('Map instance or coordinates are not valid.');
    }
  };