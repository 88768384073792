export const ValidateCoordinates = (input) => {
  // Regex for single coordinate pair
  const singlePairRegex = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;

  // Regex for multiple coordinate pairs
  const multiplePairsRegex = /^(-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?(\n|$))+$/;

  // Check if the input matches either regex pattern
  const isSinglePairMatch = input.match(singlePairRegex) !== null;
  const isMultiplePairsMatch = input.match(multiplePairsRegex) !== null;

  // Return true if the input matches either pattern
  return isSinglePairMatch || isMultiplePairsMatch;
};


export const compareCoordinates = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false; // Check if they have the same length
  for (let i = 0; i < arr1.length; i++) {
    // Compare values with a small tolerance for floating point numbers
    if (Math.abs(arr1[i] - arr2[i]) > Number.EPSILON) {
      return false;
    }
  }
  return true;
};