import React, { useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { GeolocateControl } from "mapbox-gl";
import Popup from "../popup/popup";
import { config } from "../../services/config";
import { ValidateCoordinates } from "../../services/coordinatesValidator";
import { flyToLocation } from "../../services/flyTo";
import { addRoute } from "../../services/getRoutes";
const FitnessVillages = ({ map, fitnessVillages, mapIcons, theme }) => {
  const markersRef = useRef([]);
  const [userLocation, setUserLocation] = useState(null);
  let layerRef = useRef([]);

  const parseCoordinates = (coordinatesString) => {
    return coordinatesString
      .trim() // Remove extra spaces and newlines
      .split("\n") // Split by newline
      .map((coord) => {
        const [lat, lng] = coord.split(",").map(Number); // Split by comma and convert to numbers
        return [lng, lat]; // Reverse to [lng, lat] format
      });
  };

  React.useEffect(() => {
    if (map && theme && fitnessVillages && fitnessVillages.length > 0) {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current.current = [];
      const geolocate = new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      });

      geolocate.on("geolocate", (e) => {
        const lon = e.coords.longitude;
        const lat = e.coords.latitude;
        setUserLocation([lon, lat]);
      });

      fitnessVillages.forEach((fitness, index) => {
        if (fitness.coordinates && ValidateCoordinates(fitness.coordinates)) {
          const trackCoordinates = parseCoordinates(fitness.coordinates);
          const sourceId = `fitness-${index}`;
          const layerId = `fitness-${index}`;
          const outLineId = `fitness-outline-${index}`;

          if (!map.getSource(sourceId)) {
            map.addSource(sourceId, {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [trackCoordinates],
                },
              },
            });
          }
          if (!map.getLayer(layerId)) {
            map.addLayer({
              id: layerId,
              type: "fill",
              source: sourceId,
              layout: {},
              paint: {
                "fill-color": fitness.theme ? fitness.theme : theme.theme,
                "fill-opacity": 0.5,
              },
            });
            layerRef.current.current.push(layerId);
          } else {
            map.setLayoutProperty(layerId, "visibility", "visible");
            layerRef.current.current.push(layerId);
          }
          if (!map.getLayer(outLineId)) {
            map.addLayer({
              id: outLineId,
              type: "line",
              source: sourceId,
              layout: {},
              paint: {
                "line-color": "#000",
                "line-width": theme.useThickLine ? 4 : 2,
              },
            });
            layerRef.current.current.push(outLineId);
          } else {
            map.setLayoutProperty(outLineId, "visibility", "visible");
            layerRef.current.current.push(outLineId);
          }

          // Create a DOM element for the marker
          const el = document.createElement("div");
          el.className = "marker";
          el.style.backgroundImage = `url(${
            config.baseUrl + mapIcons?.icon?.url
          })`;
          el.style.width = "48px";
          el.style.height = "48px";
          el.style.backgroundSize = "100%";
          el.style.cursor = "pointer";

          // Create the popup
          const PopupId = `fitnessBtn-${index}`;
          const googleDirectionId = `fitnessVillageGoogleBtn-${index}`;

          const popup = new mapboxgl.Popup({ offset: 25, closeButton: true })
            .setHTML(
              Popup({ theme, item: fitness, PopupId, googleDirectionId })
            )
            .on("open", () => {
              // Custom styling for the close button
              const closeButton = document.querySelector(
                ".mapboxgl-popup-close-button"
              );
              if (closeButton) {
                closeButton.textContent = ""; // Remove the "×"
              }
            });

          // Create and add the marker
          const landmarkMarker = new mapboxgl.Marker(el)
            .setLngLat(trackCoordinates[0])
            .setPopup(popup) // sets a popup on this marker
            .addTo(map);

          markersRef.current.push(landmarkMarker);

          map.on("click", () => {
            setTimeout(() => {
              const directionBtn = document.getElementById(PopupId);
              if (directionBtn) {
                directionBtn.addEventListener("click", () => {
                  popup.remove();
                  if (userLocation) {
                    addRoute(
                      userLocation,
                      trackCoordinates[0],
                      map,
                      `addRoute-11`
                    );
                  } else {
                    if ("geolocation" in navigator) {
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          const newUserLocation = [
                            position.coords.longitude,
                            position.coords.latitude,
                          ];
                          setUserLocation(newUserLocation);
                          addRoute(
                            newUserLocation,
                            trackCoordinates[0],
                            map,
                            `addRoute-11`
                          );
                        },
                        () => {
                          alert(
                            "Please enable location services to get directions."
                          );
                          geolocate.trigger();
                        }
                      );
                    } else {
                      alert(
                        "Geolocation is not supported by your browser. Please use a modern browser to get directions."
                      );
                    }
                  }
                });
              }
              const googleMapBtn = document.getElementById(googleDirectionId);
              if (googleMapBtn) {
                googleMapBtn.addEventListener("click", () => {
                  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&map_action=map&center&destination=${trackCoordinates[0][1]},${trackCoordinates[0][0]}&zoom=17`;
                  window.open(googleMapsUrl, "_blank");
                });
              }
            }, 0);
          });

          // Add click event to marker
          el.addEventListener("click", () => {
            popup.addTo(map);
          });

          // Add click event to marker
          el.addEventListener("click", () => {
            // Add popup to the map
            popup.addTo(map);
            flyToLocation(map, trackCoordinates[0]);
          });
        }
      });
    }
    return () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current.current.forEach((layer) =>
        map.setLayoutProperty(layer, "visibility", "none")
      );
    };
  }, [map, fitnessVillages, theme, mapIcons?.icon?.url, userLocation]);

  return null;
};

export default FitnessVillages;
