import React from 'react';
import { Link } from 'react-router-dom'; // if using react-router for navigation
import notFondImg from "../../assets/404-img.svg";

const NotFound = () => {
    return (
        <div className='not-found'>
            <div className="">
                <div className="not-found__wrapper">
                    <img className='not-found__img' src={notFondImg} alt="" />
                    <h1 className='not-found__title'>Oops! The page you're looking for doesn't exist.</h1>
                    <p className='not-found__desc'>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                    <Link className='not-found__button' to="/">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.69794 15.6143L25.1431 15.6143C27.44 15.6143 29.3021 17.4764 29.3021 19.7733L29.3021 21.299C29.3021 23.5959 27.44 25.458 25.1431 25.458L10.7084 25.458" stroke="black" stroke-width="2.08134" stroke-linejoin="round" />
                            <path d="M10.3438 11.2393C8.63527 12.9478 7.67735 13.9057 5.96881 15.6143L10.3438 19.9893" stroke="black" stroke-width="2.08134" stroke-linejoin="round" />
                        </svg>
                        <span>
                            Go Home
                        </span>
                    </Link>
                </div>
            </div></div>
    );
};

export default NotFound;
