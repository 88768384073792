import React, { useEffect, useRef, useState } from "react";
import nearby from "../../assets/nearby.svg";
import nearbActive from "../../assets/nearbActive.svg";
import closePopup from "../../assets/close-popup.svg";
import { config } from "../../services/config";
const NearbyLocation = ({
  mapData,
  map,
  headerDetails,
  activeDialog,
  openDialog,
  filterData,
}) => {
  const [coordinates, setCoordinates] = useState(null);
  const [nearestPlaces, setNearestPlaces] = useState(null);
  const [openNearByLocations, setOpenNearByLocations] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef(null);
  const modalRef2 = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        modalRef2.current &&
        !modalRef.current.contains(event.target) &&
        !modalRef2.current.contains(event.target)
      ) {
        setOpenNearByLocations(false);
        openDialog(null);
      }
    };

    // Attach the event listener to document
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, modalRef2, openDialog]);

  const addHeaderTitleInNearBy = (nearByPlaces) => {
    const filteredList = nearByPlaces.map((item) => {
      const matchingHeader = headerDetails.find(
        (headerItem) => headerItem.headerItemKey === item["item-key"]
      );

      if (matchingHeader) {
        return {
          ...item,
          headerTitle: matchingHeader.headerTitle,
        };
      }
      return item;
    });
    return filteredList;
  };

  const handleOpenDialog = (dialogName) => {
    if (activeDialog !== dialogName) {
      openDialog(dialogName);
    } else {
      openDialog(null);
    }
  };

  // Helper function to normalize coordinates
  const getFirstCoordinatePair = (place) => {
    let coordinates = null;
    if (place.routeCoordinates) {
      coordinates = place.routeCoordinates.split("\n")[0];
    } else if (place.supCoordinates) {
      coordinates = place.supCoordinates.split("\n")[0];
    } else if (place.kayakCoordinates) {
      coordinates = place.kayakCoordinates;
    } else if (place.coordinates) {
      coordinates = place.coordinates;
    }

    if (coordinates) {
      const [lat, lng] = coordinates
        .split(",")
        .map((coord) => parseFloat(coord.trim()));
      return [lat, lng];
    }

    return null;
  };

  // Filter nearby places (within 20 km radius) and limit to 6 nearest places
  const filterNearbyPlaces = (userLocation) => {
    let newNearByData = mapData["landmark"].map((item) => ({
      ...item, // Spread the original item properties
      "item-key": "landmark", // Add the new key-value pair
    }));
    filterData.forEach((options) => {
      mapData[options].forEach((item) => {
        newNearByData.push({ ...item, "item-key": options });
      });
    });
    const nearbyPlaces = newNearByData
      .map((place) => {
        const firstCoordinate = getFirstCoordinatePair(place);
        if (firstCoordinate) {
          const [lat, lng] = firstCoordinate;
          const distance = haversineDistance(
            normalizeCoordinates(userLocation),
            [lng, lat]
          );
          return { ...place, distance }; // Add distance property
        }
        return null;
      })
      .filter((place) => place && place.distance <= 10) // Filter within 20 km radius
      .sort((a, b) => a.distance - b.distance) // Sort by distance
      .slice(0, 6); // Limit to 6 nearest places

    if (nearbyPlaces.length > 0) {
      setNearestPlaces(addHeaderTitleInNearBy(nearbyPlaces));
      // addMarkersToMap(nearbyPlaces); // Add markers for these places
    } else {
      // setOpenNearByLocations(true);
      // alert("No nearby places found.");
    }
  };

  // normalize coordinates before passing
  const normalizeCoordinates = (coords) => {
    // If coords is an array, return as is.
    if (Array.isArray(coords)) {
      return coords;
    }
    // If coords is an object { lng, lat } or { lon, lat }, return array [lng, lat].
    if (coords.lng && coords.lat) {
      return [coords.lng, coords.lat];
    }
    if (coords.lon && coords.lat) {
      return [coords.lon, coords.lat];
    }
    throw new Error("Invalid coordinates format");
  };

  // Haversine formula to calculate the distance between two coordinates
  const haversineDistance = (coords1, coords2) => {
    const toRad = (value) => (value * Math.PI) / 180;

    const R = 6371; // Earth radius in km
    const lat1 = coords1[1];
    const lon1 = coords1[0];
    const lat2 = coords2[1];
    const lon2 = coords2[0];
    // return;
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const getLocationAndShowNearby = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false); // Stop loader after data loads
    }, 2000);
    setOpenNearByLocations(!openNearByLocations);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = [longitude, latitude];
          filterNearbyPlaces(currentLocation);
        },
        (error) => {
          alert("Please enable location services to see nearby places.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const focusMap = (item) => {
    const coordinates = getFirstCoordinatePair(item);
    if (!coordinates) return;

    const [lat, lng] = coordinates;

    map.flyTo({
      center: [lng, lat],
      zoom: 16,
      essential: true,
    });
  };

  const getUserLocation = () => {
    // setOpenNearByLocations(!openNearByLocations);
    handleOpenDialog("nearbyDialog");
    if (coordinates) {
      getLocationAndShowNearby();
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = [longitude, latitude];
          setCoordinates(currentLocation);
          map.flyTo({
            center: currentLocation,
            zoom: 14,
            essential: true,
          });
          // setHasAnimated(true);
          getLocationAndShowNearby();
        },
        (error) => {},
        {
          enableHighAccuracy: true, // This enables high accuracy for better location precision
          timeout: 10000, // Timeout after 10 seconds
          maximumAge: 0, // No caching of the location
        }
      );
    } else {
      // console.error("Geolocation is not supported by this browser.");
    }

    // const geolocateControl = new mapboxgl.GeolocateControl({
    //   positionOptions: {
    //     enableHighAccuracy: true,
    //   },
    //   trackUserLocation: true,
    //   showUserHeading: true,
    // });

    // map.addControl(geolocateControl);
    // geolocateControl.trigger();
  };

  return (
    <>
      {activeDialog === "nearbyDialog" && (
        <div
          ref={modalRef}
          className={`nearby-location-open ${
            activeDialog === "nearbyDialog" ? "open" : ""
          }`}
        >
          <div className="nearby-location-open__wrapper">
            <div className="close-popup">
              <img
                src={closePopup}
                alt="Close"
                onClick={() => {
                  openDialog(null);
                }}
              />
            </div>

            {/* Loader Section */}
            {isLoading && (
              <div className="loader">{/* Loading nearby locations... */}</div>
            )}

            {/* Nearby Places Section */}
            {!isLoading && (
              <div className="nearby-location-open__list">
                {!nearestPlaces?.length && (
                  <div className="nearby-location-open__item">
                    <p className="nearby-location-open-card__desc not-found-error">
                      No nearby places found!
                    </p>
                  </div>
                )}
                {nearestPlaces &&
                  nearestPlaces
                    .filter((item) => item.showOnMap)
                    .map((item) => (
                      <div
                        key={item.documentId}
                        className="nearby-location-open__item"
                        onClick={() => focusMap(item)}
                      >
                        <div className="nearby-location-open-card">
                          {item?.thumbnail?.url && (
                            <img
                              className="nearby-location-open-card__image"
                              src={`${config.baseUrl + item?.thumbnail?.url}`}
                              alt={item.title}
                            />
                          )}
                          <div className="nearby-location-open-card__content">
                            <span className="nearby-location-open-card__name">
                              {item.headerTitle}
                            </span>
                            <h2 className="nearby-location-open-card__title">
                              {item.title}
                            </h2>
                            <p className="nearby-location-open-card__desc">
                              {item.shortDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            )}
          </div>
        </div>
      )}

      <div className="nearby-location">
        <div className="nearby-location__wrapper">
          <div
            ref={modalRef2}
            className={`nearby-location__icon nearby
              ${activeDialog === "nearbyDialog" ? "active" : ""}
              `}
            onClick={() => getUserLocation()}
          >
            <img src={nearby} alt="Nearby Places" className="img nearby-img" />
            <img
              src={nearbActive}
              alt="Nearby Places"
              className="img nearby-img active"
            />
          </div>
          {/* location access */}
          {/* <div
            className="nearby-location__icon nearby"
            onClick={getUserLocation}
          >
            <img src={locationDirction} alt="Your Location" className="img" />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default NearbyLocation;
