// cyclingTracks.js
import React, { useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { GeolocateControl } from "mapbox-gl";
import Popup from "../popup/popup";
import { addRoute } from "../../services/getRoutes";
import { ValidateCoordinates } from "../../services/coordinatesValidator";
import { flyToLocation } from "../../services/flyTo"; // Import flyToLocation

const CyclingTracks = ({ map, cyclingTracks, mapIcons, theme }) => {
  const markersRef = useRef([]);
  const [userLocation, setUserLocation] = useState(null);
  let layerRef = useRef([]);

  const geolocate = React.useMemo(() => new GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
    },
    trackUserLocation: true,
    showUserHeading: true,
  }), []);

  geolocate.on("geolocate", (e) => {
    const lon = e.coords.longitude;
    const lat = e.coords.latitude;
    setUserLocation([lon, lat]);
  });

  const parseCoordinates = (coordinatesString) => {
    return coordinatesString
      .trim() // Remove extra spaces and newlines
      .split("\n") // Split by newline
      .map((coord) => {
        const [lat, lng] = coord.split(",").map(Number); // Split by comma and convert to numbers
        return [lng, lat]; // Reverse to [lng, lat] format
      });
  };

  React.useEffect(() => {
    if (map && cyclingTracks && cyclingTracks.length > 0) {
      // Clear previous markers
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current = [];

      cyclingTracks.forEach((track, index) => {
        if (
          track.showOnMap &&
          track.rangeOfCoordinates &&
          ValidateCoordinates(track.rangeOfCoordinates)
        ) {
          const trackCoordinates = parseCoordinates(track.rangeOfCoordinates);

          const sourceId = `cyclingTrack-${index}`;
          const layerId = `cyclingTrack-${index}`;

          // Add the track line
          if (!map.getSource(sourceId)) {
            map.addSource(sourceId, {
              type: "geojson",
              data: {
                type: "Feature",
                properties: {
                  name: track.name,
                  description: track.description,
                },
                geometry: {
                  type: "LineString",
                  coordinates: trackCoordinates,
                },
              },
            });
          }

          if (!map.getLayer(layerId)) {
            map.addLayer({
              id: layerId,
              type: "line",
              source: sourceId,
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": track?.theme ? track?.theme : theme.theme,
                "line-width": theme.useThickLine ? 6 : 3,
              },
            });
            layerRef.current.push(layerId);
          } else {
            map.setLayoutProperty(layerId, "visibility", "visible");
            layerRef.current.push(layerId);
          }

          const PopupId = `cyclingTrackBtn-${index}`;
          const googleDirectionId = `cyclingTrackGooglebtn-${index}`;
          // Create the popup
          const popup = new mapboxgl.Popup({ offset: 35, closeButton: true })
            .setHTML(Popup({ theme, item: track, PopupId, googleDirectionId }))
            .on("open", () => {
              // Custom styling for the close button
              const closeButton = document.querySelector(
                ".mapboxgl-popup-close-button"
              );
              if (closeButton) {
                closeButton.textContent = ""; // Remove the "×"
              }
            });
          // Create and add the marker

          const markerPoint = Math.floor(trackCoordinates.length / 2);
          const middleCoordinate = trackCoordinates[markerPoint];

          const startMarker = new mapboxgl.Marker({
            color: theme.theme,
          })
            .setLngLat(middleCoordinate)
            .setPopup(popup) // sets a popup on this marker
            .addTo(map);

          startMarker.getElement().style.cursor = "pointer";
          markersRef.current.push(startMarker);

          // Add click event to fly to location when marker is clicked
          startMarker.getElement().addEventListener("click", () => {
            flyToLocation(map, middleCoordinate, 14, 1.4, [0, 150]);
            popup.addTo(map); // Open popup on marker click
          });

          map.on("click", () => {
            setTimeout(() => {
              const directionBtn = document.getElementById(PopupId);
              if (directionBtn) {
                directionBtn.addEventListener("click", () => {
                  popup.remove();
                  if (userLocation) {
                    addRoute(
                      userLocation,
                      trackCoordinates[0],
                      map,
                      `addRoute-11`
                    );
                  } else {
                    if ("geolocation" in navigator) {
                      navigator.geolocation.getCurrentPosition(
                        (position) => {
                          const newUserLocation = [
                            position.coords.longitude,
                            position.coords.latitude,
                          ];
                          setUserLocation(newUserLocation);
                          addRoute(
                            newUserLocation,
                            trackCoordinates[0],
                            map,
                            `addRoute-11`
                          );
                        },
                        () => {
                          alert(
                            "Please enable location services to get directions."
                          );
                          geolocate.trigger();
                        }
                      );
                    } else {
                      alert(
                        "Geolocation is not supported by your browser. Please use a modern browser to get directions."
                      );
                    }
                  }
                });
              }
              const googleMapBtn = document.getElementById(googleDirectionId);
              if (googleMapBtn) {
                googleMapBtn.addEventListener("click", () => {
                  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&map_action=map&center&destination=${trackCoordinates[0][1]},${trackCoordinates[0][0]}&zoom=17`;
                  window.open(googleMapsUrl, "_blank");
                });
              }
            }, 0);
          });
        }
      });
    }

    return () => {
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];
      layerRef.current.forEach((layer) =>
        map.setLayoutProperty(layer, "visibility", "none")
      );
    };
  }, [map, cyclingTracks, theme, userLocation, geolocate]);

  return null;
};

export default CyclingTracks;
