import "./App.css";
import MapComponent from "./MapComponent";
import NotFound from "./components/notFound/NotFound"; // Import NotFound component
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import necessary components from react-router-dom
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
import useLandscapeClass from "./components/useLandscapeClass/useLandscapeClass";

function App() {
  useLandscapeClass();
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<MapComponent />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
