import mapboxgl from "mapbox-gl";

export const addRoute = (start, end, map, id) => {
  if (start && end && map && id) {
    let layerRef = [];
    // Show loader
    const loader = document.getElementById("loader");
    loader.style.display = "flex";

    // Remove the previous layer and source with the same ID
    if (map.getLayer(id)) {
      map.removeLayer(id);
    }
    if (map.getSource(id)) {
      map.removeSource(id);
    }

    // Fetch directions
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${start[0]},${start[1]};${end[0]},${end[1]}?alternatives=false&steps=true&geometries=geojson&overview=full&access_token=${mapboxgl.accessToken}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Hide loader
        loader.style.display = "none";

        if (data?.routes?.length > 0) {
          const route = data.routes[0].geometry.coordinates;
          const geojson = {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: route,
            },
          };

          const bounds = route.reduce((bounds, coord) => {
            return bounds.extend(coord);
          }, new mapboxgl.LngLatBounds());

          map.fitBounds(bounds, { padding: 20 });

          // Add the new route source and layer
          map.addSource(id, {
            type: "geojson",
            data: geojson,
          });

          map.addLayer({
            id: id,
            type: "line",
            source: id,
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#5e6473",
              "line-width": 6,
            },
          });

          layerRef.push(id);
        }
      })
      .catch((error) => {
        loader.style.display = "none";
        console.error("Error fetching route:", error);
      });
  }
};
